@font-face {
  font-family: 'NeueBit';
  src: url('../fonts/ppneuebit-regular-webfont.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'NeueBitBold';
  src: url('../fonts/ppneuebit-bold-webfont.woff2') format('woff');
  font-display: swap;
}

@font-face {
  font-family: 'CMU Typewriter Text';
  src: url('../fonts/CMUTypewriter-Regular.woff2') format('woff2'),
    url('../fonts/CMUTypewriter-Regular.woff') format('woff');
  font-weight: 1000;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  user-select: none;
}

html, body {
  margin: 0;
  background-color: black !important;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: grab;
}

body:active {
  cursor: grabbing;
}

.keen-slider__slide {
  background-size: contain;
  max-height: 90vh;
  cursor: grab;
}

.keen-slider__slide:active {
  cursor: grabbing;
}

[class^='number-slide'],
[class*=' number-slide'] {
  display: flex;
  align-items: left;
  justify-content: left;
  font-size: 1.666em;
  color: #fff;
  font-weight: 500;
  min-height: 50vh;
  max-height: 100vh;
  font-family: 'CMU Typewriter Text';
  width: 100vw;
  z-index: 9;
}

p {
  font-family: 'CMU Typewriter Text';
  text-transform: uppercase;
  cursor: pointer;
}

svg {
  width: 3vh;
  height: 3vw;
  /* transform: rotate(90deg) scaleX(.45); */
}

svg:hover {
  color: red;
}

.number-slide1 {
  background-image: url('./pstr/pstr001.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide2 {
  background-image: url('./pstr/pstr002.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide3 {
  background-image: url('./pstr/pstr003.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide4 {
  background-image: url('./pstr/pstr004.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide5 {
  background-image: url('./pstr/pstr005.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide6 {
  background-image: url('./pstr/pstr006.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide7 {
  background-image: url('./pstr/pstr007.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide8 {
  background-image: url('./pstr/pstr008.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide9 {
  background-image: url('./pstr/pstr009.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide10 {
  background-image: url('./pstr/pstr010.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}

.number-slide11 {
  background-image: url('./pstr/pstr011.png');
  background-position: center;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  background-repeat: no-repeat;
}
